import styled from 'styled-components'
import Image from 'gatsby-image'

export const Description = styled.p`
  margin: 0 auto;
  padding: 1em 0;
  margin-bottom: 4em;
`

export const TeamList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding: 0;
  list-style: none;
`

export const TeamItem = styled.li`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 2em;
  padding: 8px;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: calc(100% / 3);
  }
`

export const TeamItemContent = styled.div`
  position: relative;
  overflow: hidden;
`

export const TeamItemImg = styled(Image)`
  display: block;
  width: 100%;
`

export const TeamItemName = styled.p`
  text-align: center;
  font-weight: 700;
`

export const TeamItemPosition = styled.p`
  text-align: center;
`

export const TeamItemAdditionalInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateY(100%);
  transition: transform 0.3s ease-in;

  ${TeamItemContent}:hover & {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transform: translateY(0);
  }
`
