import styled from 'styled-components'

const directions = {
  up: 'translate(0, 20vh)',
  left: 'translate(15vw, 0)',
}

const getTranslate = (direction) => directions[direction] || directions.up

export const StyledFadeInSection = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible, translate }) =>
    isVisible ? 'translate(0, 0)' : getTranslate(translate)};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
`
