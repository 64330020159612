import React from 'react'
import Section from '../shared/Section'
import Container from '../shared/Container'
import SectionTitle from '../shared/SectionTitle'
import {
  Description,
  TeamList,
  TeamItem,
  TeamItemImg,
  TeamItemName,
  TeamItemPosition,
  TeamItemAdditionalInfo,
  TeamItemContent,
} from './styles'
import FadeInSection from '../FadeInSection'
import Contact from '../shared/Contact'

const Team = ({ title, description, list }) => (
  <Section>
    <Container>
      <SectionTitle>{title}</SectionTitle>
      <Description>{description}</Description>
      <TeamList>
        {list.map(
          ({
            fullname,
            phone,
            position,
            photo: {
              childImageSharp: { fluid },
            },
          }) => (
            <TeamItem as={FadeInSection} key={encodeURIComponent(fullname)}>
              <TeamItemContent>
                <TeamItemImg fluid={fluid} />
                <TeamItemAdditionalInfo>
                  {phone && <Contact type="phone" value={phone} />}
                </TeamItemAdditionalInfo>
              </TeamItemContent>
              <div>
                <TeamItemName>{fullname}</TeamItemName>
                {position && <TeamItemPosition>{position}</TeamItemPosition>}
              </div>
            </TeamItem>
          )
        )}
      </TeamList>
    </Container>
  </Section>
)

export default Team
