import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Section from '../components/shared/Section'
import Container from '../components/shared/Container'
import Team from '../components/Team/Team'
import PageTitle from '../components/shared/PageTitle/PageTitle'
import SEO from '../components/Seo/Seo'

export const AboutPageTemplate = ({
  title,
  content,
  contentComponent,
  team: { title: teamTitle, description: teamDescription, list: teamList },
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      <Section>
        <Container>
          <PageTitle>{title}</PageTitle>
          <PageContent className="content" content={content} />
        </Container>
      </Section>
      <Team title={teamTitle} description={teamDescription} list={teamList} />
    </>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { title, team, description },
      html,
    },
  } = data

  return (
    <Layout>
      <SEO description={description} />
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={title}
        content={html}
        team={team}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        description
        title
        team {
          description
          title
          list {
            fullname
            phone
            position
            photo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      html
    }
  }
`
